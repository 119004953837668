/* Reset default margin and padding for all elements */
* {
    margin: 0;
    padding: 0;
}
body {
     background-color:#d47090;
     background-image:url('background.jpeg');
  background-size: cover; /* Make the background image cover the entire viewport */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background image from repeating */
background-color: #c1cfdb; /* Background color for the entire body */
   

 }

/* Style the header */
header {
    background-color: #e6c3ce; /* Set the background color */
    color: #f4f4f4; /* Set the text color */
    padding: 10px 0; /* Add some padding to the top and bottom */
}

/* Style the navigation menu inside the header */
header nav ul {
    list-style-type: none; /* Remove list bullets */
    text-align: center; /* Center-align the menu */
}

header nav ul li {
    display: inline; /* Display menu items horizontally */
    margin-right: 20px; /* Add some spacing between menu items */
}

header nav ul li a {
    text-decoration: none; /* Remove underlines from links */
    color: #fff; /* Set link text color */
    font-weight: bold; /* Make the text bold */
}

/* Style the container */
.container {
    background-color:#F5F5DC;
    max-width: 500px; /* Adjust the width as needed */
    margin: 50px auto; /* Add margin to the top and bottom, auto centers the container horizontally */
    padding: 20px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Style the content inside the container */
.content {
    display: flex; /* Use flexbox to align image and form side by side */
    align-items: center; /* Vertically center the content */
    max-width: 800px;
    margin: 0 auto; /* Center-align the content horizontally */
}
.content1 img {
    max-width: 100%; /* Limit image width to the container width */
    height: auto; /* Maintain the aspect ratio */
}

/* Style the image */
.image {
    flex: 1; /* Let the image grow to take available space */
    text-align: center; /* Center-align the image horizontally */
}

.image img {
    max-width: 80%; /* Ensure the image doesn't exceed its container */
}

/* Style the form */
.form {
   
    flex: 2; /* Let the form grow to take available space */
    max-width: 800px; /* Adjust the width of the form */
    margin: 0 auto; /* Center-align the form horizontally */
}

h1 {
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"],
select {
    width: 100%; /* All fields have the same width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding and borders in width */
}

select {
    height: 40px;
}

button[type="submit"] {
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

.forgot-password {
    text-align: center;
    margin-top: 10px;
}

.forgot-password a {
    color: #007BFF;
    text-decoration: none;
}

/* Style the footer */
footer {
    position: fixed;
    bottom: 0; /* Stick it to the bottom of the viewport */
    left: 0; /* Align it to the left */
    width: 100%; /* Full width */
    background-color: #e8d1d8; /* Set the background color */
    color: #000; /* Set the text color */
    text-align: center; /* Center-align the content */
    padding: 10px 0; /* Add some padding to the top and bottom */
}

/* Add these styles to your existing style.css or create a new one */

/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e6c3ce;
    color: #000;
    padding: 10px 20px;
}

.navbar a {
    text-decoration: none;
    color: #000;
}
.navbar a:hover {
    color: #007BFF; /* Change link text color to blue on hover */
}
.logo a {
    font-size: 24px;
    font-weight: bold;
}

/* Toggle Menu Styles */
.menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
}
/* style.css */

/* Base styles for larger screens */
.dashboard {
  display: flex;
  flex-wrap: wrap;
}

.course {
  margin: 10px;
  flex: 1;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}
.section2 {
  margin: 10px;
  flex: 1;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}


 

/* Style for the Student Profile section */
.course h2 {
  font-size: 24px; /* Increase the font size for smaller screens */
}

.course table {
  width: 100%; /* Make the table take full width */
}

.course table th {
  text-align: left; /* Align table headers to the left */
}

.course table td {
  text-align: left; /* Align table data to the left */
}

.course .button {
  margin: 10px 0; /* Add margin to the Edit Profile button */
}
/* style.css */

/* Default styles for larger screens */
.section2 {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}



/* Chat box styles */
.chat-box2 {
  width: 50%;
  margin: 0 auto;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .section2 {
    width: 90%;
  }

 

  /* Chat box styles for smaller screens */
  .chat-box2 {
    width: 90%;
  }
}

/* Media Query for Responsive Design */
@media screen and (max-width: 768px) {
    .menu-icon {
        display: flex;
    }


 .dashboard {
    flex-direction: column;
  }

  .course {
    margin: 10px 0;
    max-width: 100%;
  }


    #navbar {
        display: none;
        flex-direction: column;
        background-color: #333;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        z-index: 1;
    }

    #navbar.show {
        display: flex;
    }

    #navbar ul {
        list-style-type: none;
        padding: 0;
    }

    #navbar li {
        padding: 10px;
        text-align: center;
    }

    #navbar a {
        text-decoration: none;
        color: #000;
    }

  .button-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically on smaller screens */
  align-items: center; /* Center buttons horizontally */
}
  /* Adjust the chat box layout for smaller screens */
  .chat-box {
    width: 100%;
  }
 .content1 {
    padding: 10px; /* Example padding for smaller screens */
  }

  .section2 {
    flex-direction: column; /* Stack buttons vertically */
  }

}




/* Style for the content container */
.content1 {
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

/* Style for the dashboard */
.dashboard {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow courses to wrap to the next row if there's not enough space */
}

/* Style for individual course cards */
.course {
    flex: 1;
    max-width: calc(33.33% - 20px); /* Adjust the maximum width for three courses in a row with gaps */
    margin: 0 10px 20px 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.course img {
    max-width: 30%;
    
}

.course h3 {
    margin: 10px 0;
    font-size: 24px;
}

.course button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Style for the chat box */
.chat-box {
    max-width: 300px;
    margin: -10px auto;
    padding: 20px;
  
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.chat-box h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.chat-messages {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    
}

.message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
}

.from-user {
    background-color: #e6c3ce;
    color: #000;
    text-align: left;
}

.from-admin {
    background-color: #e6c3ce;
    color: #000;
    text-align: right;
}

.chat-input {
    margin-top: 20px;
}

#chat-input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

#send-button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.section {
    flex: 1;
    max-width: calc(33.33% - 20px); /* Adjust the maximum width for three sections in a row with gaps */
    margin: 0 10px 20px 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.section h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Style for buttons within sections */
.button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
/* Style for the chat box */
.chat-box1 {
    max-width: 300px;
    margin: 20px auto;
    padding: 20px;
  
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.chat-box1 h2 {
    font-size: 24px;
    margin-bottom: 20px;
}
/* Style for the Chat Box */
.chat-box2 {
    flex: 1; /* Take up equal space */
    max-width: calc(50% - 10px); /* Adjust the maximum width for two sections with a gap */
    margin: 0 5px; /* Add a small gap between sections */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}



.chat-box2 h2 {
    font-size: 24px;
    margin-bottom: 20px;
}
/* Style for the QA Officer Section */
.section2 {
    flex: 1; /* Take up equal space */
    max-width: calc(50% - 10px); /* Adjust the maximum width for two sections with a gap */
    margin: 0 5px; /* Add a small gap between sections */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.section2 h2 {
    font-size: 24px;
    margin-bottom: 20px;
}
/* Style for the chat buttons */
.chat-button {
    margin: 5px;
    padding: 10px 20px;
   
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Style for the chat buttons in the message container */
.message.from-admin {
    text-align: center;
    padding: 0; /* Reset padding for the message container */
}


/* Style for the content container */
.content3 {
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 18px; /* Add rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.left-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.right-div {
    flex: 1;
    text-align: center;
    padding: 10px;
    position: relative;
}

.paragraph h1 {
    font-size: 38px;
    margin-bottom: 20px;
}

.paragraph p {
    font-size: 26px;
    line-height: 1.5;
}

.right-div img {
    max-width: 102.5%;
    height: auto;
    position: absolute;
    top: 0;
    right:0;
    bottom:0;
}
.left-div .paragraph p {
    font-size: 20px; /* Change the font size as needed */
    color: #333; /* Change the text color as needed */
    line-height: 1.6; /* Change the line height as needed */
}
/* Style for the content container */
.content4 {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 20px;
    border-radius: 18px; /* Add rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1); /* Add a subtle box shadow */
}

.content4 h1 {
    font-size: 32px;
    margin-bottom: 20px;
}

.content4 h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.content4 p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

/* Add the CSS for the QA Officer Dashboard component */

.content1 {
  text-align: center;
  padding: 20px;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow elements to wrap to the next line on small screens */
}

.section2 {
  text-align: center;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust this value to control the spacing between buttons */
}

.chat-box2 {
  text-align: center;
}

.chat-messages {
  max-height: 200px; /* Limit the height to allow scrolling if there are many messages */
  overflow-y: auto;
}

.chat-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Adjust this value to control the spacing between input and button */
}

/* Add the CSS for aligning buttons in three rows */

.button-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust this value to control the spacing between buttons */
}

.button1 {
  margin: 5px; /* Adjust this value to control the spacing between rows and buttons */
}
/* Logout button styling */
.logout-button {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #333; /* Customize the background color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  float: right; /* Align the button to the right */
  margin-right: 20px; 
}
